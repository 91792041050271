<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">收款结算单</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">优惠申请列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100" />
              <el-table-column label="企业名称" align="left" prop="compName" show-overflow-tooltip width="200" />
              <el-table-column label="班级编码" align="left" prop="projectCode" show-overflow-tooltip width="200" />
              <el-table-column label="班级名称" align="left" prop="projectName" show-overflow-tooltip width="200" />
              <el-table-column label="培训人数" align="left" prop="projectJoinNum" show-overflow-tooltip width="100" />
              <el-table-column label="应收金额" align="left" prop="totalFee" show-overflow-tooltip width="120" />
              <el-table-column label="优惠后金额" align="left" prop="discountFee" show-overflow-tooltip width="120" />
              <el-table-column label="折扣" align="left" prop="areaName" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  {{ (scope.row.discountFee / scope.row.totalFee).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="申请人" align="left" prop="applyUserName" show-overflow-tooltip width="100" />
              <el-table-column label="申请理由" align="left" prop="applyReason" show-overflow-tooltip width="200" />
              <el-table-column label="审核人" align="left" prop="auditUserName" show-overflow-tooltip width="100" />
              <el-table-column label="审核状态" align="left" prop="auditState" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  {{ scope.row.auditState ? $setDictionary("BILL_PROJECT_DISCOUNT_STATE", scope.row.auditState) : '--' }}
                </template>
              </el-table-column>
              <el-table-column label="备注" align="left" prop="auditRemark" show-overflow-tooltip min-width="200" />
              <el-table-column label="操作" align="center" width="100" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="listSeeFileDataOpen(scope.row)"
                    :disabled="!scope.row.applyAnnex">查看附件</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <div class="uploadFile">
          <el-upload class="upload-demo upload-btn" :on-change="uploadChange" :file-list="applyAndSeeData.applyAnnexList"
            :show-file-list="false" :auto-upload="false">
            <el-button class="bgc-bv" size="mini">上传附件</el-button>
          </el-upload>
          <div class="applyAnnexList" v-for="(item, index) in applyAndSeeData.applyAnnexList" :key="index">
            <span class="applyAnnexList_1">{{ item.fileName }}</span>
            <span class="applyAnnexList_2" @click="lookExl(item)">预览</span>
            <span class="applyAnnexList_3" @click="removeExl(item, index)">删除</span>
          </div>
          <div class="cz">
            <el-button size="small" @click="$router.back();">取消</el-button>
            <el-button type="primary" size="small" class="bgc-bv" @click="applyAndSeeDataDetermine()">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 - 列表查看附件 -->
    <el-dialog title="查看附件" :visible.sync="listSeeFileData.dialogVisible" width="900px" top="40px"
      @close="listSeeFileDataClose">
      <div class="listSeeFileData">
        <div class="listSeeFileData_1" v-for="(item, index) in listSeeFileData.fileList" :key="index">
          <span class="listSeeFileData_1_1">
            {{ item.fileName }}
          </span>
          <span class="listSeeFileData_1_2" @click="listSeeFileDataSee(item)">
            预览
          </span>
        </div>
        <div class="listSeeFileData_2">
          <div id="pdf-cert1" style="height: 600px"
            v-if="listSeeFileData.fileType == 'pdf' || seeFileData.fileType == 'PDF'">
          </div>
          <div v-else>
            <img :src="listSeeFileData.fileUrl" alt="" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹框 - 上传附件 - 预览 -->
    <el-dialog title="预览" :visible.sync="seeFileData.dialogVisible" @close="seeFileDataClose" width="700px" top="30px"
      center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="seeFileData.fileType == 'pdf' || seeFileData.fileType == 'PDF'">
        </div>
        <div v-else>
          <img :src="seeFileData.fileUrl" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "financialSettlement_settlement_discountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        billId: "", // 结算单id
      },
      // 列表查看附件
      listSeeFileData: {
        dialogVisible: false, // 弹框状态
        fileList: [], // 附件列表
        fileType: "", // 附件类型
        fileUrl: "", // 附件预览url
      },
      // 上传附件数据
      applyAndSeeData: {
        applyAnnexList: [], // 上传附件列表
      },
      // 上传附件数据 - 预览
      seeFileData: {
        dialogVisible: false, // 弹框状态
        fileType: "", // 预览文件类型
        fileUrl: "", // 文件地址
      },
    };
  },
  computed: {

  },
  mounted() {

  },
  watch: {},
  created() {
    this.lastPageData.billId = this.$route.query.billId;
    this.getApplyAnnexList();
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        billId: this.$route.query.billId
      };
      this.doFetch({
        url: "/biz/bill/project/discount/bill/pageList",
        params,
        pageNum,
      });
    },
    // 列表查看附件 - 弹框 - 打开
    listSeeFileDataOpen(row) {
      // 申请过 有申请id的掉详情（详情接口反的是可输入的那些字段）
      if (row.discountId) {
        this.$post('/biz/bill/project/discount/file/list', {
          discountId: row.discountId
        }).then(res => {
          console.log(res)
          this.listSeeFileData.dialogVisible = true;
          this.listSeeFileData.fileList = res.data || [];
          if (res.data.length) {
            this.listSeeFileData.fileType = res.data[0].fileType;
            this.listSeeFileData.fileUrl = res.data[0].fileUrl;
            if (res.data[0].fileType == "pdf" || res.data[0].fileType == "PDF") {
              this.$nextTick(() => {
                pdf.embed(item.fileUrl, "#pdf-cert1");
              });
            } else {
              this.listSeeFileData.fileUrl = res.data[0].fileUrl;
            }
          }
        });
      }
    },
    // 列表查看附件 - 弹框 - 打开 - 点击文件列表预览
    listSeeFileDataSee(row) {
      if (row.fileType == "pdf" || row.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        });
      } else {
        this.listSeeFileData.fileUrl = row.fileUrl;
      }
    },
    // 列表查看附件 - 弹框 - 关闭
    listSeeFileDataClose() {
      this.listSeeFileData = this.$options.data().listSeeFileData;
    },
    // 获取上传附件列表
    getApplyAnnexList() {
      this.$post('/biz/new/bill/billFileList', {
        billId: this.$route.query.billId,
      }).then(res => {
        this.applyAndSeeData.applyAnnexList = res.data.fdNewBillFileBOList || [];
      });

    },
    // 上传附件
    uploadChange(file, fileList) {
      let _this = this;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = extension === "jpg";
      const isJPG = extension === "JPG";
      const isPng = extension === "png";
      const isPNG = extension === "PNG";
      const isPDF = extension === "PDF";
      const isPdf = extension === "pdf";
      if (!isJpg && !isJPG && !isPng && !isPNG && !isPDF && !isPdf) {
        _this.$message.error("只能上传后缀是.jpg或.png或者.pdf的文件");
        return;
      }
      if (size > 3) {
        _this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      _this
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          _this.applyAndSeeData.applyAnnexList.push({
            billId: this.billId,
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileUrl: result.data.fileURL,
            fileType: extension,
          });
        })
        .catch(() => { })
    },
    // 上传附件 - 预览 - 打开弹框
    lookExl(item) {
      this.seeFileData.dialogVisible = true;
      this.seeFileData.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        });
      } else {
        this.seeFileData.fileUrl = item.fileUrl;
      }
    },
    // 上传附件 - 预览 - 关闭弹框
    seeFileDataClose() {
      // 数据重置
      this.seeFileData = this.$options.data().seeFileData;
    },
    // 上传附件- 删除
    removeExl(item, index) {
      this.applyAndSeeData.applyAnnexList.splice(index, 1);
    },
    // 上传附件 - 确定
    applyAndSeeDataDetermine() {
      this.$post('/biz/new/bill/saveFiles', {
        billId: this.lastPageData.billId,
        fileList: this.applyAndSeeData.applyAnnexList
      }).then(res => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.listSeeFileData {
  display: flex;

  .listSeeFileData_1 {
    width: 40%;

    .listSeeFileData_1_1 {
      width: 80%;
    }

    .listSeeFileData_1_2 {
      width: 20%;
      color: #409eff;
      cursor: pointer;
    }
  }

  .listSeeFileData_2 {
    width: 60%;
    border-left: 1px solid #e9e9e9;
    padding-left: 20px
  }
}

/deep/ .uploadFile .upload-btn .el-upload {
  height: auto;
  border: none;
}

.uploadFile {
  height: 150px;
  padding: 10px;
  background: #f0f0f0;
  margin-top: 10px;
  position: relative;

  .applyAnnexList {
    display: flex;
    height: 30px;
    line-height: 30px;

    .applyAnnexList_1 {
      width: 400px;
      line-height: 30px;
    }

    .applyAnnexList_2 {
      color: #409eff;
      margin: 0 10px;
      cursor: pointer;
    }

    .applyAnnexList_3 {
      color: #F56C6C;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .cz {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
